import apiClient from "@/services/api";

export default {
  async getLegalEntities({ commit, rootState }, { page, size, filter }) {
    try {
      const { data } = await apiClient.post(
        "/api/project/legal_entity/get_all",
        {
          project_id: rootState.applicationState.currentProject.id,
          page,
          size,
          filter,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getLegalEntity({ commit, rootState }, { legal_entity_id }) {
    try {
      const { data } = await apiClient.post("/api/project/legal_entity/get", {
        project_id: rootState.applicationState.currentProject.id,
        legal_entity_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setLegalEntity", data);
    } catch (error) {
      throw error.message;
    }
  },

  async deleteLegalEntity({ commit, rootState }, { legal_entity_id }) {
    try {
      const { data } = await apiClient.post(
        "/api/project/legal_entity/delete",
        {
          project_id: rootState.applicationState.currentProject.id,
          legal_entity_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setLegalEntity", undefined);
    } catch (error) {
      throw error.message;
    }
  },

  async createLegalEntity({ commit, rootState }, { legal_entity }) {
    try {
      const { data } = await apiClient.post(
        "/api/project/legal_entity/create",
        {
          project_id: rootState.applicationState.currentProject.id,
          legal_entity,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setLegalEntity", data);
    } catch (error) {
      throw error.message;
    }
  },

  async updateLegalEntity({ commit, rootState }, { legal_entity }) {
    try {
      const { data } = await apiClient.post(
        "/api/project/legal_entity/update",
        {
          project_id: rootState.applicationState.currentProject.id,
          legal_entity,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setLegalEntity", data);
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociates({ commit }, { filters }) {
    try {
      const { data } = await apiClient.get(
        `/api/project/legal_entity/${legal_entity_id}/associate/get_all`,
        { params: filters }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setAssociates", data);
    } catch (error) {
      throw error.message;
    }
  },

  async getAdmins({ commit }, { filters }) {
    try {
      const { data } = await apiClient.get(
        `/api/project/legal_entity/${legal_entity_id}/admin/get_all`,
        { params: filters }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setAdmins", data);
    } catch (error) {
      throw error.message;
    }
  },
};
