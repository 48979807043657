import shiftsHelper from "@/helpers/shifts";
import moment from "moment-timezone";

export default {
  mixins: [shiftsHelper],
  data() {
    return {
      pagination: {
        pageSizeOptions: ["10", "50", "100"],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
      },

      tableScrollHeight: 0,

      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          key: "shiftId",
          align: "center",
          sorter: true,
        },
        {
          title: "Date",
          key: "date",
          scopedSlots: { customRender: "date" },
          align: "center",
          sorter: true,
        },
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          align: "center",
          sorter: true,
        },
        {
          title: "Providers",
          key: "providers",
          scopedSlots: { customRender: "providers" },
          align: "center",
        },
        {
          title: "Confirmation",
          key: "confirmation",
          scopedSlots: { customRender: "confirmation" },
          align: "center",
        },
        {
          title: "Authorizations",
          key: "authorizations",
          scopedSlots: { customRender: "authorizations" },
          align: "center",
        },
        {
          title: "History",
          key: "history",
          scopedSlots: { customRender: "history" },
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
        },
      ],

      nestedColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          className: "column-wrap",
        },
        {
          title: "Start",
          dataIndex: "start",
          key: "start",
        },
        {
          title: "End",
          dataIndex: "end",
          key: "end",
        },
        {
          title: "Work Hrs",
          dataIndex: "work_hours",
        },
        {
          title: "Pay Hrs",
          dataIndex: "pay_hours",
        },
        {
          title: "L1 Start",
          dataIndex: "first_lunch_start",
          key: "first_lunch_start",
        },
        {
          title: "L1 End",
          dataIndex: "first_lunch_end",
          key: "first_lunch_end",
        },
        {
          title: "L1 Dur",
          dataIndex: "lunch_duration",
          key: "lunch_duration",
        },
        {
          title: "L2 Start",
          dataIndex: "second_lunch_start",
          key: "second_lunch_start",
        },
        {
          title: "L2 End",
          dataIndex: "second_lunch_end",
          key: "second_lunch_end",
        },
        {
          title: "L2 Dur",
          dataIndex: "second_lunch_duration",
          key: "second_lunch_duration",
        },
      ],
    };
  },
  methods: {
    calculateTableHeight() {
      this.tableScrollHeight = window.innerHeight - 260;
    },

    expandedRowRender(record) {
      const formattedDurations = this.formatShiftAuthDurations(record.shift);

      const data = [
        {
          key: record.id,
          type: "Scheduled",
          date: formattedDurations.scheduled.date,
          start: formattedDurations.scheduled.start,
          end: formattedDurations.scheduled.end,
          first_lunch_start: "Unscheduled",
          first_lunch_end: "Unscheduled",
          second_lunch_start: "Unscheduled",
          second_lunch_end: "Unscheduled",
          duration:
            record.shift.scheduled_duration !== 0
              ? Util.formatMinutesToHoursAndMinutesDuration(
                  record.shift.scheduled_duration
                )
              : "-",
          work_hours: this.formatWorkHours(
            record.shift.scheduled_start
              ? moment.utc(record.shift.scheduled_start)
              : null,
            record.shift.scheduled_end
              ? moment.utc(record.shift.scheduled_end)
              : null,
            record.shift.lunch_start
              ? moment.utc(record.shift.lunch_start)
              : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start
              ? moment.utc(record.shift.second_lunch_start)
              : null,
            record.shift.second_lunch_end
              ? moment.utc(record.shift.second_lunch_end)
              : null
          ),
          pay_hours: this.formatPayHours(
            record.shift.scheduled_start
              ? moment.utc(record.shift.scheduled_start)
              : null,
            record.shift.scheduled_end
              ? moment.utc(record.shift.scheduled_end)
              : null,
            record.shift.scheduled_start
              ? moment.utc(record.shift.scheduled_start)
              : null,
            record.shift.scheduled_end
              ? moment.utc(record.shift.scheduled_end)
              : null,
            record.shift.lunch_start
              ? moment.utc(record.shift.lunch_start)
              : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start
              ? moment.utc(record.shift.second_lunch_start)
              : null,
            record.shift.second_lunch_end
              ? moment.utc(record.shift.second_lunch_end)
              : null
          ),
          lunch_duration: "Unscheduled",
          second_lunch_duration: "Unscheduled",
        },
        {
          key: record.id,
          type: "Actual",
          date: formattedDurations.actual.date,
          start: formattedDurations.actual.start,
          end: formattedDurations.actual.end,
          first_lunch_start: formattedDurations.actual.first_lunch_start,
          first_lunch_end: formattedDurations.actual.first_lunch_end,
          second_lunch_start: formattedDurations.actual.second_lunch_start,
          second_lunch_end: formattedDurations.actual.second_lunch_end,
          duration:
            record.shift.actual_duration !== 0
              ? Util.formatMinutesToHoursAndMinutesDuration(
                  record.shift.actual_duration
                )
              : "-",
          work_hours: this.formatWorkHours(
            record.shift.actual_work_start
              ? moment.utc(record.shift.actual_work_start)
              : null,
            record.shift.actual_work_end
              ? moment.utc(record.shift.actual_work_end)
              : null,
            record.shift.actual_lunch_start
              ? moment.utc(record.shift.actual_lunch_start)
              : null,
            record.shift.actual_lunch_end
              ? moment.utc(record.shift.actual_lunch_end)
              : null,
            record.shift.actual_second_lunch_start
              ? moment.utc(record.shift.actual_second_lunch_start)
              : null,
            record.shift.actual_second_lunch_end
              ? moment.utc(record.shift.actual_second_lunch_end)
              : null
          ),
          pay_hours: this.formatPayHours(
            record.shift.scheduled_start
              ? moment.utc(record.shift.scheduled_start)
              : null,
            record.shift.scheduled_end
              ? moment.utc(record.shift.scheduled_end)
              : null,
            record.shift.actual_work_start
              ? moment.utc(record.shift.actual_work_start)
              : null,
            record.shift.actual_work_end
              ? moment.utc(record.shift.actual_work_end)
              : "Waived",
            record.shift.actual_lunch_start
              ? moment.utc(record.shift.actual_lunch_start)
              : null,
            record.shift.actual_lunch_end
              ? moment.utc(record.shift.actual_lunch_end)
              : "Waived",
            record.shift.actual_second_lunch_start
              ? moment.utc(record.shift.actual_second_lunch_start)
              : null,
            record.shift.actual_second_lunch_end
              ? moment.utc(record.shift.actual_second_lunch_end)
              : null
          ),
          lunch_duration:
            record.shift.actual_lunch_duration !== 0
              ? Util.formatMinutesToHoursAndMinutesDuration(
                  record.shift.actual_lunch_duration
                )
              : "Waived",
          second_lunch_duration:
            record.shift.actual_second_lunch_duration !== 0
              ? Util.formatMinutesToHoursAndMinutesDuration(
                  record.shift.actual_second_lunch_duration
                )
              : "Waived",
        },
        {
          key: record.id,
          type: "Signed",
          date: formattedDurations.signed.date,
          start: formattedDurations.signed.start,
          end: formattedDurations.signed.end,
          first_lunch_start: formattedDurations.signed.first_lunch_start,
          first_lunch_end: formattedDurations.signed.first_lunch_end,
          second_lunch_start: formattedDurations.signed.second_lunch_start,
          second_lunch_end: formattedDurations.signed.second_lunch_end,
          duration:
            record.shift.duration !== 0
              ? Util.formatMinutesToHoursAndMinutesDuration(
                  record.shift.duration
                )
              : "-",
          work_hours: this.formatWorkHours(
            record.shift.work_start
              ? moment.utc(record.shift.work_start)
              : null,
            record.shift.work_end ? moment.utc(record.shift.work_end) : null,
            record.shift.lunch_start
              ? moment.utc(record.shift.lunch_start)
              : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start
              ? moment.utc(record.shift.second_lunch_start)
              : null,
            record.shift.second_lunch_end
              ? moment.utc(record.shift.second_lunch_end)
              : null
          ),
          pay_hours: this.formatPayHours(
            record.shift.scheduled_start
              ? moment.utc(record.shift.scheduled_start)
              : null,
            record.shift.scheduled_end
              ? moment.utc(record.shift.scheduled_end)
              : null,
            record.shift.work_start
              ? moment.utc(record.shift.work_start)
              : null,
            record.shift.work_end ? moment.utc(record.shift.work_end) : null,
            record.shift.lunch_start
              ? moment.utc(record.shift.lunch_start)
              : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start
              ? moment.utc(record.shift.second_lunch_start)
              : null,
            record.shift.second_lunch_end
              ? moment.utc(record.shift.second_lunch_end)
              : null
          ),
          lunch_duration:
            record.shift.lunch_duration !== 0
              ? Util.formatMinutesToHoursAndMinutesDuration(
                  record.shift.lunch_duration
                )
              : "Waived",
          second_lunch_duration:
            record.shift.second_lunch_duration !== 0
              ? Util.formatMinutesToHoursAndMinutesDuration(
                  record.shift.second_lunch_duration
                )
              : "Waived",
        },
      ];

      return (
        <a-table
          id="shift-table"
          size="small"
          columns={this.nestedColumns}
          dataSource={data}
          rowKey="key"
          pagination={false}
        />
      );
    },
  },
};
