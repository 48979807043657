<template>
  <div v-if="isVisibleBalance" class="wrapper">
    <span class="title">Stripe Balance:</span>
    <span class="text">${{ balance / 100 }}</span>
  </div>
</template>

<script>
import { notification, Statistic } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions: paymentActions } = createNamespacedHelpers("payment");

export default {
  components: {
    "a-statistic": Statistic,
  },
  data() {
    return {
      balance: 0,

      loading: false,
    };
  },
  props: {
    payment_system_id: {
      type: Number,
      default: undefined,
    },
  },
  watch: {
    payment_system_id: {
      handler(newVal) {
        newVal && this.fetchBalance();
      },
      immediate: true,
    },
  },
  computed: {
    isVisibleBalance() {
      return (
        this.$store.state.applicationState.associate.permissions.includes(
          "validate_payments"
        ) ||
        this.$store.state.applicationState.associate.permissions.includes(
          "process_payments"
        )
      );
    },
    formatBalance() {
      return (this.balance / 100).toFixed(2);
    },
  },
  methods: {
    ...paymentActions(["getPaymentBalance"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchBalance() {
      this.loading = true;
      try {
        const payload = {
          payment_system_id: this.payment_system_id,
        };

        const data = await this.getPaymentBalance(payload);

        this.balance = data;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  border: 1px solid #d9d9d9;
  padding: 0 15px;
  height: 32px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
