import router from "@/router";
import store from "@/store/index";
import axios from "axios";
import Cookies from "js-cookie";

const getRootAddress = () => {
  if (process.env.NODE_ENV === "production") {
    return "/";
  } else {
    return "http://localhost:8000/";
  }
};

const apiClient = axios.create({
  baseURL: getRootAddress(),
  // timeout: 30000,
  withCredentials: true,
});

const pendingRequests = {};

const removePendingRequest = (url, abort = false) => {
  // check if pendingRequests contains our request URL
  if (url && pendingRequests[url]) {
    // if we want to abort ongoing call, abort it
    if (abort) {
      pendingRequests[url].abort();
    }
    // remove the request URL from pending requests
    delete pendingRequests[url];
  }
};

apiClient.interceptors.request.use(
  (config) => {
    const timeZone = store.state.applicationState.timeZone;
    if (timeZone) {
      config.headers["Client-Timezone"] = timeZone;
    }

    const csrf = Cookies.get("csrftoken");
    if (csrf) {
      config.headers["X-CSRFToken"] = csrf;
    }

    if (config?.cancelPreviousRequests && config?.url && !config.signal) {
      // remove request URL from pending requests and also abort ongoing call with same URL
      removePendingRequest(config.url, true);

      const abortController = new AbortController(); //create new AbortController
      config.signal = abortController.signal; // assign it's signal into request config
      pendingRequests[config.url] = abortController; // store AbortController in the pending requests map
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    removePendingRequest(response.config.url);

    return response;
  },
  (error) => {
    removePendingRequest(error.config?.url);

    const { response } = error;

    if (response && response.status === 401) {
      if (store.state.applicationState.token) {
        apiClient.post("/sign_out");
        store.commit("setToken", null);
      }

      router.push("/auth").catch(() => {});
    }

    return Promise.reject(error);
  }
);

export default apiClient;
