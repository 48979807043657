<template>
  <div>
    <h2 class="view-header">
      <span class="flex gap-2 align-items-center justify-content-center">
        Associate Profiles
        <associate-profile
        :visible.sync="associateProfileModalVisible"
        :associate-number="editingAssociateNumber"
        @edited="handleEditedAssociateProfile"
        @close="endEditAssociateProfile"
        @clone="openCloneAssociate"
      />
        <a-tooltip placement="right">
          <template slot="title"> Start Tour </template>
          <a-icon
            @click.stop="startTour()"
            class="flex align-items-center cursor-pointer"
            type="question-circle"
            theme="twoTone"
          />
        </a-tooltip>
      </span>
    </h2>
    <v-tour name="profilesTour" :steps="tourSteps" :options="vueTourOptions" />
    <a-modal
      v-model="cloneAssociateVisible"
      title="Create New Profile"
      width="1000px"
      :footer="null"
      :destroy-on-close="true"
    >
      <clone-associate
        :key="cloneAssociateModalKey"
        :associate-number="cloningAssociateNumber"
        @close="endCloneAssociate"
      />
    </a-modal>
    <send-notification
      :associate="associateProfileToSendSms"
      @close="closeSmsModal"
    />
    <add-note :associate="associateProfileToAddNote" @close="closeNoteModal" />
    <associate-filters
      :current-filters="filters"
      :visible.sync="associateFiltersVisible"
      @apply="applyFilters"
    />

    <div class="table_controls_and_data">
      <div class="flex gap-2 align-items-center">
        <a-button
          icon="user"
          type="primary"
          @click="openNewUserWithAssociateView"
          >Add Associate</a-button
        >
      </div>

      <div class="flex gap-2 align-items-center">
        <a-button icon="filter" @click="showAssociateFilterModal">
          Filters
        </a-button>

        <a-button
          icon="undo"
          :disabled="filtersAreEmpty"
          @click="resetAssociateFilters"
        >
          Reset Filters
        </a-button>
      </div>
    </div>
    <a-table
      rowKey="id"
      size="small"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
      :customRow="
        (record) => ({
          on: {
            click: () => startEditAssociateProfile(record.associate_number),
          },
        })
      "
    >
      <span slot="associate" slot-scope="text, record" :key="record.photo_url">
        <grouped-associate-info
          :first-name="record.first_name"
          :last-name="record.last_name"
          :photo-url="record.photo_url"
          :associate-number="record.associate_number"
        />
      </span>
      <span slot="isActive" slot-scope="text, record">
        <a-popover v-if="record.suspension" title="Suspension">
          <template slot="content">
            <p>
              <b>Author: </b>
              <span>
                {{
                  combineFullName(
                    record.suspension.author.first_name,
                    record.suspension.author.last_name
                  )
                }}
              </span>
            </p>
            <p>
              <b>Reason</b>
              <span>
                {{ record.suspension.reason }}
              </span>
            </p>
            <p>
              <b>Duration: </b>
              <span v-if="record.suspension.indefinite_period">
                Indefinite
              </span>
              <span v-else>
                {{ record.suspension.days_amount }}
              </span>
            </p>
            <p v-if="!record.suspension.indefinite_period">
              <b>Days left: </b>
              <span>
                {{ record.suspension.remaining_days }}
              </span>
            </p>
          </template>
          <a-tag :color="getTagColor(record)">{{
            getAssociateStatus(record)
          }}</a-tag>
        </a-popover>
        <a-tag v-else :color="getTagColor(record)">{{
          getAssociateStatus(record)
        }}</a-tag>
      </span>
      <span slot="verified" slot-scope="text, record">
        <a-tag :color="getVerifiedTagColor(record)">{{
          record.is_verified ? "YES" : "NO"
        }}</a-tag>
      </span>
      <span slot="scheduleType" slot-scope="text, record">
        {{ renderScheduleType(record.schedule_type) }}
      </span>
      <span slot="payStatus" slot-scope="text, record">
        {{ renderPayStatus(record.payment_info.status) }}
      </span>
      <span slot="rate" slot-scope="text, record">
        {{ renderRate(record.payment_info.rate) }}
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-tooltip>
            <template slot="title"> Open Profile </template>
            <a-button
              id="edit-associate-profile"
              icon="user"
              @click.stop="startEditAssociateProfile(record.associate_number)"
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Open Shifts </template>
            <a-button
              id="associate-shifts-btn"
              icon="profile"
              :disabled="!record.associate_shifts_exists"
              @click.stop="openAssociateShiftsList(record.associate_number)"
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Send Message </template>
            <a-button
              id="send-message-btn"
              icon="message"
              :disabled="!record.phone"
              @click.stop="openSmsSendModal(record)"
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Open Note </template>
            <a-button
              id="note-btn"
              icon="snippets"
              @click.stop="openNoteModal(record)"
            />
          </a-tooltip>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  Table,
  Icon,
  Button,
  Modal,
  Checkbox,
  Select,
  Tag,
  Popover,
  Tooltip,
} from "ant-design-vue";
import api from "@/api";
import Util from "@/util";
import AssociateProfile from "@/components/associate-profile.vue";
import SendNotification from "@/components/send-notification.vue";
import AddNote from "@/components/add-note.vue";
import CloneAssociate from "@/modules/profile/components/profile-associate-clone.vue";
import router from "@/router";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import { vueTourOptions } from "@/const";
import AssociateProfilesFilters from "@/components/associate-profiles-filters.vue";

const ScheduleType = {
  FIXED: "fixed",
  SELF_SCHEDULE: "self_schedule",
  FREE: "free",
};

const PayStatus = Object.freeze({
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  ON_CALL: "on_call",
  TERMINATED: "terminated",
});

const StatusTagColors = Object.freeze({
  GRAY: "#b4b4b4",
  GREEN: "#34db30",
  RED: "#ff4646",
});

export default {
  components: {
    "a-table": Table,
    "a-icon": Icon,

    "a-button": Button,
    "a-button-group": Button.Group,

    "a-modal": Modal,
    "a-checkbox": Checkbox,
    "a-select": Select,
    "a-select-option": Select.Option,

    "a-tag": Tag,
    "a-popover": Popover,
    "a-tooltip": Tooltip,

    "associate-profile": AssociateProfile,
    "send-notification": SendNotification,
    "add-note": AddNote,
    "clone-associate": CloneAssociate,

    "grouped-associate-info": GroupedAssociateInfo,
    "associate-filters": AssociateProfilesFilters,
  },
  mixins: [api],
  props: {
    filters: {
      type: Object,
    },
    editingAssociate: {
      type: String,
    },
  },
  data() {
    return {
      personalComponentSelected: true,
      associateFiltersVisible: false,

      editingAssociateNumber: "",
      cloningAssociateNumber: undefined,

      rateFrom: undefined,
      rateTo: undefined,

      showAllPaymentTypes: false,

      tableData: [],

      columns: [
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          sorter: true,
        },
        {
          title: "Job classification",
          dataIndex: "role.name",
          key: "role",
          sorter: true,
        },
        {
          title: "Payment Type",
          dataIndex: "payment_info.type",
          sorter: true,
        },
        {
          title: "Pay Status",
          scopedSlots: { customRender: "payStatus" },
          key: "pay_status",
          sorter: true,
        },
        {
          title: "Rate, $",
          key: "rate",
          scopedSlots: { customRender: "rate" },
          sorter: true,
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "isActive" },
          align: "center",
        },
        {
          title: "Verified",
          key: "verified",
          scopedSlots: { customRender: "verified" },
          align: "center",
        },
        {
          title: "Schedule Type",
          key: "schedule_type",
          scopedSlots: { customRender: "scheduleType" },
          sorter: true,
        },
        {
          title: "Actions",
          scopedSlots: { customRender: "actions" },
        },
      ],
      pagination: {
        pageSizeOptions: ["10", "50", "100"],
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true,
        onChange: (page) => {
          this.pagination.current = page;
          this.loadData();
        },
        onShowSizeChange: (current, size) => {
          this.pagination.pageSize = size;
          this.loadData();
        },
      },
      loading: false,

      associateProfileModalKey: 0,
      cloneAssociateModalKey: 0,
      associateProfileToSendSms: undefined,
      associateProfileToAddNote: undefined,
      associateProfileModalVisible: false,

      cloneAssociateVisible: false,

      tourSteps: [
        {
          target: ".ant-table-row",
          header: {
            title: "Associate Profiles",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.profilesTourBeforeStartCallback,
          content: `
            <p>This section displays key information about each associate. Each row contains:</p>
            <p><b>Associate Number</b>: Unique identifier for the associate.</p>
            <p><b>Full Image</b>: Picture of the associate (if exists).</p>
            <p><b>Classification</b>: Job classification of the associate.</p>
            <p><b>Payment Type</b>: Method of payment (e.g., salary, hourly).</p>
            <p><b>Payment Status</b>: Current status of payment (e.g., active, suspended).</p>
            <p><b>Rate</b>: Pay rate of the associate.</p>
            <p><b>Schedule Type</b>: Type of work schedule the associate follows.</p>
          `,
        },
        {
          target: ".ant-table-row td:nth-child(7)",
          header: {
            title: "Associate Status",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "This field shows whether the associate is currently employed or has\n" +
            "been terminated.",
        },
        {
          target: "#edit-associate-profile",
          header: {
            title: "Associate Edit",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            if (!this.editingAssociateNumber) {
              return;
            }
            return new Promise((resolve) => {
              this.endEditAssociateProfile();
              setTimeout(() => {
                resolve();
              }, 1000);
            });
          },
          content: `
          <p>Clicking 'Edit' allows you to view and modify various details about the associate, including:</p>
          <p><b>Personal Information</b>: Basic personal details of the associate.</p>
          <p><b>Job Information</b>: Details about the associate's job role and responsibilities.</p>
          <p><b>Payment Information</b>: Information related to the associate's payment method and status.</p>
          <p><b>Signed Documents</b>: Documents signed by the associate.</p>
          <p><b>Associate KPI</b>: Key Performance Indicators for the associate.</p>
          <p><b>Clone Associate</b>: This feature allows you to clone the associate with a different payment type, enabling them to work as both an employee and a contractor, or in different roles or for different companies.</p>
          `,
        },
        {
          target: ".profile-form-menu li:nth-child(1)",
          header: {
            title: "KPI",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            return new Promise((resolve) => {
              this.startEditAssociateProfile(
                this.tableData[0].associate_number
              );
              setTimeout(() => {
                resolve();
              }, 1000);
            });
          },
          content: "Here you can view an associate's KPI",
        },
        {
          target: ".profile-form-menu li:nth-child(2)",
          header: {
            title: "Personal",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            document
              .querySelector(".profile-form-menu li:nth-child(2)")
              .click();
          },
          content: "Here you can view an associate's personal information",
        },
        {
          target: ".profile-form-menu li:nth-child(3)",
          header: {
            title: "Job",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            document
              .querySelector(".profile-form-menu li:nth-child(3)")
              .click();
          },
          content:
            "Here you can view an associate's job information (ex. job classification, schedule type)",
        },
        {
          target: ".profile-form-menu li:nth-child(4)",
          header: {
            title: "Pay",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: async () => {
            if (this.editingAssociateNumber) {
              document
                .querySelector(".profile-form-menu li:nth-child(4)")
                .click();
              return;
            }

            await new Promise((resolve) => {
              this.startEditAssociateProfile(
                this.tableData[0].associate_number
              );
              setTimeout(() => {
                resolve();
              }, 1000);
            });
            document
              .querySelector(".profile-form-menu li:nth-child(4)")
              .click();
          },
          content: "Here you can view an associate's payment information,",
        },
        {
          target: "#associate-shifts-btn",
          header: {
            title: "Associate Shifts",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            return new Promise((resolve) => {
              this.endEditAssociateProfile();
              setTimeout(() => {
                resolve();
              }, 500);
            });
          },
          content:
            "This button lets you view all shifts worked by the associate.",
        },
        {
          target: "#send-message-btn",
          header: {
            title: "Send Message Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Use this button to send a message to the associate, whether it's to\n" +
            "notify them of something or to request action.",
        },
        {
          target: "#note-btn",
          header: {
            title: "Note Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: "This button allows you to leave a note for associate",
        },
        {
          target: "#active-btn",
          header: {
            title: "Terminate/Activate Associate Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: "This button allows you to activate or terminate associate",
        },
      ],
    };
  },
  watch: {
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.pagination.current = 1;
          this.loadData();
        }
      },
      deep: true,
      immediate: true,
    },
    editingAssociate: {
      handler(newVal) {
        if (newVal) {
          this.startEditAssociateProfile(newVal);

          this.$router
            .replace({ query: { editingAssociate: undefined } })
            .catch(() => {});
        }
      },
      immediate: true,
    },
  },
  computed: {
    vueTourOptions() {
      return vueTourOptions;
    },
    filtersAreEmpty() {
      return Object.values(this.filters).every((value) => value === undefined);
    },
  },
  methods: {
    // filters
    showAssociateFilterModal() {
      this.associateFiltersVisible = true;
    },
    resetAssociateFilters() {
      this.loading = true;

      this.$router.replace({ query: {} }).catch(() => {});
    },
    applyFilters(filters) {
      this.loading = true;

      this.associateFiltersVisible = false;

      this.$router.replace({ query: filters }).catch(() => {});
    },
    handleEditedAssociateProfile() {
      this.loadData();
    },
    async loadData(sorter) {
      this.loading = true;

      try {
        const { body } = await this.apiGetAssociateProfiles(
          this.filters.associateNumber,
          this.filters.role,
          this.filters.scheduleType,
          this.rateFrom,
          this.rateTo,
          this.showAllPaymentTypes,
          this.filters.isActive ? true : undefined,
          this.filters.isVerified ? true : undefined,
          this.pagination.current,
          this.pagination.pageSize,
          sorter
        );

        this.tableData = body.associates;
        this.pagination.total = body.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    renderScheduleType(scheduleType) {
      switch (scheduleType) {
        case ScheduleType.FIXED:
          return "Strict";
        case ScheduleType.SELF_SCHEDULE:
          return "Flexible";
        case ScheduleType.FREE:
          return "Free";
      }
    },
    renderPayStatus(payStatus) {
      switch (payStatus) {
        case PayStatus.TERMINATED:
          return "Terminated";
        case PayStatus.ON_CALL:
          return "On Call";
        case PayStatus.PART_TIME:
          return "Part Time";
        case PayStatus.FULL_TIME:
          return "Full Time";
        default:
          return "N/A";
      }
    },

    renderRate(rateInCents) {
      return rateInCents / 100;
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      const associateData = this.tableData.find(record => record.associate_number === associateNumber);
      if (associateData) {
        this.associateNumber = associateData.associate_number;
        this.fullName = `${associateData.first_name} ${associateData.last_name}`;
      }
      this.associateProfileModalVisible = true;
    },

    openAssociateShiftsList(associateNumber) {
      this.$router
        .push({
          path: "/shifts",
          query: { associateNumber: associateNumber },
        })
        .catch(() => {});
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
      this.associateProfileModalVisible = false;
    },

    handleEdited() {
      this.loadData();
    },

    combineFullName(firstName, lastName) {
      return Util.combineAssociateName(firstName, lastName);
    },

    openSmsSendModal(associate) {
      this.associateProfileToSendSms = associate;
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },
    openNoteModal(associate) {
      this.associateProfileToAddNote = associate;
    },
    closeNoteModal() {
      this.associateProfileToAddNote = undefined;
    },
    getTagColor(record) {
      if (!record.is_active) {
        return StatusTagColors.GRAY;
      } else if (record.suspension) {
        return StatusTagColors.RED;
      } else if (record.termination) {
        return StatusTagColors.GRAY;
      } else {
        return StatusTagColors.GREEN;
      }
    },
    getVerifiedTagColor(record) {
      return record.is_verified ? StatusTagColors.GREEN : StatusTagColors.RED;
    },
    getAssociateStatus(record) {
      if (!record.is_active) {
        return "DISABLED";
      } else if (record.suspension) {
        return "SUSPENDED";
      } else if (record.termination) {
        return "TERMINATED";
      } else {
        return "ACTIVE";
      }
    },
    handleTableChange(sorter) {
      this.loadData(sorter);
    },

    openCloneAssociate() {
      this.cloneAssociateVisible = true;
      this.cloningAssociateNumber = this.editingAssociateNumber;
    },
    endCloneAssociate(newAssociateNumber) {
      this.cloneAssociateVisible = false;
      this.cloningAssociateNumber = undefined;
      this.editingAssociateNumber = newAssociateNumber;
      this.loadData();
    },
    openNewUserWithAssociateView() {
      router.push("/create-user-with-associate");
    },

    profilesTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.tableData && this.tableData.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },

    startTour() {
      this.$tours["profilesTour"].start();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style>
.associate-profile-modal-wrapper {
  overflow-x: auto;
  width: 100%;
  height: 600px;
}
</style>
