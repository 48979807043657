import { render, staticRenderFns } from "./profile-associate-clone.vue?vue&type=template&id=cf891dc8&scoped=true&"
import script from "./profile-associate-clone.vue?vue&type=script&lang=js&"
export * from "./profile-associate-clone.vue?vue&type=script&lang=js&"
import style0 from "./profile-associate-clone.vue?vue&type=style&index=0&id=cf891dc8&prod&scoped=true&lang=css&"
import style1 from "../../../assets/css/form-styles.css?vue&type=style&index=1&id=cf891dc8&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf891dc8",
  null
  
)

export default component.exports