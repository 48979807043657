import apiClient from "@/services/api";

export default {
  /**
   * Fetches all roles for the current project
   * @param {Object} context - Vuex context
   * @param {Function} context.commit - Vuex commit method
   * @param {Object} context.rootState - Vuex root state
   * @throws {Error} When API request fails or returns error code
   */
  async getRoles({ commit, rootState }) {
    try {
      const { data } = await apiClient.post("/api/project/role/get_all", {
        project_id: rootState.applicationState.currentProject.id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while loading the job classifications."
          }  Please try again later`
        );
      }

      commit("setRoles", data.roles);
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Deletes a specific role from the current project
   * @param {Object} context - Vuex context
   * @param {Function} context.commit - Vuex commit method
   * @param {Object} context.state - Vuex module state
   * @param {Object} context.rootState - Vuex root state
   * @param {Object} payload - Deletion parameters
   * @param {number} payload.id - ID of the role to delete
   * @throws {Error} When API request fails or returns error code
   */
  async deleteRole({ commit, state, rootState }, { id }) {
    try {
      const { data } = await apiClient.post("/api/delete_project_role", {
        role_id: id,
        project_id: rootState.applicationState.currentProject.id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while deleting the job classifications."
          }  Please try again later`
        );
      }

      commit(
        "setRoles",
        state.roles.filter((role) => role.id !== id)
      );
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Updates an existing role in the current project
   * @param {Object} context - Vuex context
   * @param {Function} context.commit - Vuex commit method
   * @param {Object} context.state - Vuex module state
   * @param {Object} context.rootState - Vuex root state
   * @param {Object} payload - Update parameters
   * @param {Object} payload.role - Updated role object
   * @throws {Error} When API request fails or returns error code
   */
  async updateRole({ commit, state, rootState }, { role }) {
    try {
      const { id } = role;

      const { data } = await apiClient.post("/api/project/role/update", {
        project_id: rootState.applicationState.currentProject.id,
        role,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while editing the job classifications."
          }  Please try again later`
        );
      }

      const updatedRoles = state.roles.map((role) =>
        role.id === id ? { ...role, ...data.role } : role
      );

      commit("setRoles", updatedRoles);
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Creates a new role in the current project
   * @param {Object} context - Vuex context
   * @param {Function} context.commit - Vuex commit method
   * @param {Object} context.state - Vuex module state
   * @param {Object} context.rootState - Vuex root state
   * @param {Object} payload - Creation parameters
   * @param {Object} payload.role - New role object to create
   * @throws {Error} When API request fails or returns error code
   */
  async createRole({ commit, state, rootState }, { role }) {
    try {
      const { data } = await apiClient.post("/api/project/role/create", {
        project_id: rootState.applicationState.currentProject.id,
        role,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while saving the job classifications."
          }  Please try again later`
        );
      }

      commit("setRoles", [data.role, ...state.roles]);
    } catch (error) {
      throw error.message;
    }
  },
};
