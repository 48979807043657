<template>
  <div class="associate-info">
    <div class="associate-photo" @click="openAssociateProfile">
      <user-avatar
        :width="32"
        :height="32"
        :image-path="photoUrl ? photoUrl + '?t=' + randomQueryParam : undefined"
      />
    </div>
    <div class="associate-name">
      <template v-if="role">
        <b>{{ titledFirstName + " " + titledLastName }}</b>
        <div v-if="paymentType">
          Payment Type: <b>{{ paymentType }}</b>
        </div>
        <div>
          Job Classification: <b>{{ role }}</b>
        </div>
        <div v-if="legalEntity">
          Legal Entity: <b>{{ legalEntity }}</b>
        </div>
      </template>
      <template v-else>
        <div>
          {{ titledFirstName }}
        </div>
        <div>
          {{ titledLastName }}
        </div>
      </template>
      <div v-if="associateNumber" class="associate-number">
        Associate ID: <b>{{ associateNumber }}</b>
      </div>
      <div class="unverified" v-if="unverified">Unverified</div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/user-avatar.vue";
import Util from "@/util";

export default {
  components: {
    "user-avatar": UserAvatar,
  },
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    photoUrl: {
      type: String,
      required: false,
    },
    role: {
      type: String,
      required: false,
    },
    unverified: {
      type: Boolean,
      required: false,
    },
    associateNumber: {
      type: String,
      required: false,
      default: "",
    },
    paymentType: {
      type: String,
      required: false,
    },
    legalEntity: {
      type: String,
      required: false,
    },
  },
  computed: {
    titledFirstName() {
      return Util.titleCase(this.firstName);
    },
    titledLastName() {
      return Util.titleCase(this.lastName);
    },
    randomQueryParam() {
      return (
        new Date().getTime() + "_" + Math.random().toString(36).substr(2, 9)
      );
    },
  },
  methods: {
    openAssociateProfile() {
      if (this.associateNumber) {
        const route = {
          name: "associate-profiles",
          query: { editingAssociate: this.associateNumber },
        };

        const url = this.$router.resolve(route).href;

        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.associate-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.associate-photo {
  cursor: pointer;
}

.associate-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.associate-number {
}

.unverified {
  color: #ff4646;
}
</style>
