<template>
  <div>
    <a-modal
      width="600px"
      title="Reason of Deny Authorization"
      v-model="visibleDenyReason"
      :destroy-on-close="true"
    >
      <shift-auth-deny-form
        ref="shiftAuthDenyForm"
        @reason="setComment"
        @params="setParams"
      />
      <template slot="footer">
        <div class="flex justify-content-between">
          <a-button @click="cancelDeny"> Cancel </a-button>
          <div class="flex justify-content-end">
            <a-button type="danger" @click="confirmDeny(false)"> Deny </a-button>
            <a-button type="danger" @click="confirmDeny(true)">
              Deny & Adjust
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
    <a-modal
      width="600px"
      title="Accept Authorization Comment"
      v-model="visibleAcceptComment"
      :destroy-on-close="true"
    >
      <a-input placeholder="Optional" v-model="resolveComment" />
      <template slot="footer">
        <div class="flex justify-content-between">
          <a-button @click="cancelAccept"> Cancel </a-button>
          <div class="flex justify-content-end">
            <a-button type="primary" @click="confirmAccept(false)"> Accept </a-button>
            <a-button type="primary" @click="confirmAccept(true)">
              Accept & Adjust
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <shift-auth-list-modal
      ref="shiftAuthListModal"
      :auths-list="authorizations"
      @change="setAuthorizations"
    />

    <div class="flex gap-2 justify-content-end mt-2">
      <a-button
        type="danger"
        :disabled="selectedAuthorizations.length == 0"
        @click="openDenyModal"
      >
        Deny
      </a-button>
      <a-button
        type="primary"
        :disabled="selectedAuthorizations.length == 0"
        @click="openAcceptModal"
      >
        Accept
      </a-button>
    </div>
  </div>
</template>

<script>
import ShiftAuthDenyForm from "./shift-auth-deny.vue";
import ShiftAuthListModal from "./shift-auth-list.vue";
import { createNamespacedHelpers } from "vuex";
import { Button, Input, notification, Spin } from "ant-design-vue";

const { mapActions, mapState } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-button": Button,
    "a-spin": Spin,
    "a-input": Input,
    "shift-auth-list-modal": ShiftAuthListModal,
    "shift-auth-deny-form": ShiftAuthDenyForm,
  },
  data() {
    return {
      visibleDenyReason: false,
      visibleAcceptComment: false,

      selectedAuthorizations: [],
      params: {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false,
      },

      isLoading: false,
      withAdjust: false,

      resolveComment: "",
    };
  },
  emits: ["update"],
  computed: {
    ...mapState({
      authorizations: (state) => state.shiftAuthorizations,
    }),

    fullSelectedAuths() {
      if (this.authorizations.length == 0 || this.authorizations.length == 0) {
        return [];
      }

      return this.authorizations.filter((auth) =>
        this.selectedAuthorizations.includes(auth.id)
      );
    },

    filteredDeclineReasons() {
      const reasons = {};

      if (this.fullSelectedAuths.length > 0) {
        this.fullSelectedAuths.forEach(async (auth) => {
          const declineReasons = await this.getDeclineReasons();
          const authTypeReason = declineReasons[auth.type];
          if (!authTypeReason) {
            return;
          }

          reasons[auth.type] = authTypeReason;
        });
      }

      return reasons;
    },
  },
  methods: {
    ...mapActions(["fetchShiftAuthorizations", "updateShiftAuthorizations"]),

    // modals
    openAcceptModal() {
      this.visibleAcceptComment = true;
    },

    openDenyModal() {
      const fullSelectedAuths = this.authorizations.filter((auth) =>
        this.selectedAuthorizations.includes(auth.id)
      );

      this.visibleDenyReason = true;

      this.$nextTick(() => {
        this.$refs.shiftAuthDenyForm.setAuths(fullSelectedAuths);
      });
    },

    // setters
    setComment(reason) {
      this.resolveComment = reason;
    },

    setParams(params) {
      this.params = params;
    },

    setAuthorizations(authorizations) {
      this.selectedAuthorizations = authorizations;
    },

    cancelDeny() {
      this.visibleDenyReason = false;
      this.setComment(undefined);
    },

    confirmDeny(withAdjust) {
      this.visibleDenyReason = false;

      if (
        this.resolveComment &&
        this.selectedAuthorizations &&
        this.selectedAuthorizations.length > 0
      ) {
        this.withAdjust = withAdjust;
        this.denyAuthorizations();
      } else {
        notification["warning"]({
          message: "Error",
          description: "Please fill deny reason and try again",
        });
      }
    },

    cancelAccept() {
      this.visibleAcceptComment = false;
      this.setComment(undefined);
    },

    async confirmAccept(withAdjust) {
      this.visibleAcceptComment = false;

      if (
        this.selectedAuthorizations &&
        this.selectedAuthorizations.length > 0
      ) {
        this.withAdjust = withAdjust;
        this.acceptAuthorizations();
      }
    },

    async updateAuthorizationStatus(status) {
      try {
        const payload = {
          ids: this.selectedAuthorizations,
          status,
          comment: this.resolveComment,
          params: {
            ready_for_payment: this.params.readyForPayment,
            with_writeup: this.params.withWriteUp,
            suspension: this.params.suspend,
            terminated: this.params.terminated,
            shift_action_autocomplete: this.params.shiftActionAutocomplete,
          },
        };

        await this.updateShiftAuthorizations(payload);

        this.showNotification(
          "success",
          "Success",
          "Authorization statuses were set successfully"
        );

        this.$emit("update", this.withAdjust);
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      } finally {
        this.withAdjust = false;
      }
    },

    async acceptAuthorizations() {
      await this.updateAuthorizationStatus("accepted");
    },

    async denyAuthorizations() {
      await this.updateAuthorizationStatus("declined");
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },
  },
};
</script>
