<template>
  <a-modal
    :title="type === 'attached' ? 'Attach Pay Code' : 'Create Pay Code'"
    :visible="visible"
    width="480px"
    :destroy-on-close="true"
    @cancel="closePayCodeModal"
  >
    <a-form-model
      layout="horizontal"
      :model="form"
      :rules="rules"
      ref="payCodeForm"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-alert
        :message="
          type === 'attached'
            ? 'To attach pay code please select pay code, associate and their shifts below.'
            : 'To create shifts with pay code select associates(-s), dates and pay code below.'
        "
        type="info"
        show-icon
      />

      <a-form-model-item :colon="false" label-align="left" label="Type">
        <a-select v-model="type" placeholder="Enter Type">
          <a-select-option value="independent">Independent</a-select-option>
          <a-select-option value="attached">Attached</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Pay Code"
        prop="pay_code"
      >
        <a-select v-model="payCodeProxy" placeholder="Select Pay Code">
          <a-select-option
            v-for="payCode in availablePayCodes"
            :key="payCode.id"
            :value="payCode.id"
          >
            {{ payCode.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        v-if="this.form.pay_code"
        :colon="false"
        label-align="left"
        :label="`Value, ${this.form.pay_code.type === 'total' ? '$' : 'hours'}`"
      >
        <a-input-number
          v-model="form.pay_code.value"
          :min="0"
          placeholder="Enter value"
        />
      </a-form-model-item>

      <created-form
        v-if="type === 'independent'"
        :dates.sync="form.dates"
        :associates.sync="form.associates"
        :initial-values="initialValues"
      />

      <attached-form
        v-else
        ref="attachedRef"
        :shift_ids.sync="form.shift_ids"
        :initial-values="initialValues"
      />
    </a-form-model>

    <template slot="footer">
      <div class="flex justify-content-between">
        <a-button @click="closePayCodeModal">Cancel</a-button>
        <a-button type="primary" :loading="loading" @click="submit">{{
          type === "attached" ? "Attach" : "Create"
        }}</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Alert,
  Button,
  DatePicker,
  FormModel,
  InputNumber,
  Modal,
  notification,
  Select,
  Table,
  Tag,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ShiftPayCodeAttached from "./shift-pay-code-attached.vue";
import ShiftPayCodeCreated from "./shift-pay-code-created.vue";

const { mapActions: shiftActions } = createNamespacedHelpers("shifts");

const { mapState: payCodeState, mapActions: payCodeActions } =
  createNamespacedHelpers("paycode");

const independentForm = {
  pay_code: undefined,
  associates: [],
  dates: [],
};

const independentRules = {
  pay_code: [
    {
      required: true,
      message: "Please select pay code",
      trigger: "change",
      type: "object",
    },
  ],
  associates: [
    {
      required: true,
      message: "Please select associates",
      trigger: "change",
      type: "array",
    },
  ],
  dates: [
    {
      required: true,
      message: "Please select dates",
      trigger: "change",
      type: "array",
    },
  ],
};

const attachForm = {
  pay_code: undefined,
  shift_ids: [],
};

const attachRules = {
  pay_code: [
    {
      required: true,
      message: "Please select pay code",
      trigger: "change",
      type: "object",
    },
  ],
  shift_ids: [
    {
      required: true,
      message: "Please select shifts",
      trigger: "change",
      type: "array",
    },
  ],
};

export default {
  components: {
    "a-modal": Modal,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-input-number": InputNumber,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-alert": Alert,
    "a-date-picker": DatePicker,
    "a-range-picker": DatePicker.RangePicker,
    "a-tag": Tag,
    "a-table": Table,
    "attached-form": ShiftPayCodeAttached,
    "created-form": ShiftPayCodeCreated,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shiftId: {
      type: Number,
      default: undefined,
    },
    initialValues: {
      type: Object,
      default: undefined,
    },
    add: {
      type: Function,
    },
  },
  data() {
    return {
      form: independentForm,
      rules: independentRules,
      type: "independent",

      loading: false,
    };
  },
  computed: {
    ...payCodeState({
      payCodes: (state) => state.payCodes,
    }),

    availablePayCodes() {
      switch (this.type) {
        case "attached":
          return this.payCodes.filter((item) => item.attached);
        case "independent":
          return this.payCodes.filter((item) => !item.attached);
      }
    },

    payCodeProxy: {
      get() {
        return this.form.pay_code ? Number(this.form.pay_code.id) : undefined;
      },
      set(value) {
        this.form.pay_code = this.payCodes.find((item) => item.id === value);
      },
    },
  },
  watch: {
    type: {
      handler(newVal) {
        if (newVal) this.updateFormAndRules(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    ...shiftActions(["createPayCode", "attachPayCode"]),
    ...payCodeActions(["fetchPayCodes"]),

    updateFormAndRules(newVal) {
      switch (newVal) {
        case "attached":
          this.rules = { ...attachRules };
          this.form = { ...attachForm };
          break;
        case "independent":
          this.rules = { ...independentRules };
          this.form = { ...independentForm };
          break;
      }

      if (this.$refs.attachedRef) this.$refs.attachedRef.clearData();
      if (this.$refs.payCodeForm) this.$refs.payCodeForm.resetFields();
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    closePayCodeModal() {
      this.type = "independent";
      this.updateFormAndRules("independent");
      this.$emit("update:visible", false);
    },

    // async

    async submit() {
      try {
        const valid = await new Promise((resolve) => {
          this.$refs.payCodeForm.validate(resolve);
        });

        if (!valid) {
          this.showNotification(
            "warning",
            "Validation Error",
            "Please fill in all required fields correctly."
          );

          return;
        }

        this.loading = true;

        const payload = { ...this.form };

        await (this.type === "independent"
          ? this.createPayCode(payload)
          : this.attachPayCode(payload));

        this.showNotification(
          "success",
          "Success",
          "New pay code has been successfully saved"
        );
        this.closePayCodeModal();
        this.$emit("add");
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchPayCodesInfo() {
      try {
        await this.fetchPayCodes({});
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },
  },
  mounted() {
    if (!this.payCodes.length) this.fetchPayCodesInfo();
  },
};
</script>

<style scoped>
.ant-row {
  display: flex;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.ant-calendar-picker {
  margin: 0;
  width: 100%;
}

.ant-tag {
  margin: 0;
}

.date-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
}

.ant-input-number {
  width: 100%;
}
</style>
