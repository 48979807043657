<template>
  <div>
    <h2 class="view-header">Overtime</h2>
    <div class="table_controls_and_data">
      <div class="table_data_wrapper"></div>
      <div class="table_controls_wrapper">
        <div class="table_params">
          <a-button
            :disabled="table_data.length == 0 && !not_found"
            @click="clearData"
            >Clear</a-button
          >
          <a-dropdown :disabled="table_data.length == 0">
            <a-menu slot="overlay">
              <a-menu-item key="excel">
                <download-excel
                  :data="table_data"
                  worksheet="Overtime"
                  name="overtime.xls"
                >
                  <a-icon type="file-excel" />
                  Excel
                </download-excel>
              </a-menu-item>
              <a-menu-item key="csv">
                <download-excel
                  :data="table_data"
                  :escapeCsv="false"
                  type="csv"
                  name="overtime.csv"
                >
                  <a-icon type="file-text" />
                  CSV
                </download-excel>
              </a-menu-item>
              <a-menu-item key="pdf" @click="printTable">
                <a-icon type="file-pdf" /><span>Print/PDF</span>
              </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px" :disabled="loading">
              Export
              <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </div>
        <div class="table_predefined_filters">
          <search-associates
            :multiply="true"
            style="min-width: 200px"
            :value.sync="selected_employees"
          />

          <a-date-picker
            v-model="date_from"
            :disabled-date="disabledStartDate"
            :format="select_date_format"
            placeholder="Start Date"
            style="flex: 1 1 0"
            :disabled="loading"
          />
          <a-date-picker
            v-model="date_to"
            :disabled-date="disabledEndDate"
            :format="select_date_format"
            placeholder="End Date"
            style="flex: 1 1 0"
            :disabled="loading"
          />
        </div>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="table_data"
      :loading="loading"
      key="id"
    />
  </div>
</template>

<style scoped>
.ant-table td {
  white-space: nowrap;
}
</style>

<script>
import api from "@/api";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import {
  Button,
  DatePicker,
  Select,
  Menu,
  Dropdown,
  Icon,
  Table,
  Switch,
} from "ant-design-vue";
import printJS from "print-js";
import store from "@/store";
import SearchAssociates from "@/components/search-associates.vue";

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-select": Select,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "a-dropdown": Dropdown,
    "a-table": Table,
    "a-switch": Switch,
    "download-excel": JsonExcel,
    "search-associates": SearchAssociates,
  },
  data: () => {
    return {
      loading: false,

      table_data: [],

      selected_employees: [],

      shifts: [],

      date_from: null,
      date_to: null,
      not_found: false,

      serverDateFormat: store.state.applicationState.serverDateFormat,
      select_date_format: store.state.applicationState.dateFormat,
      work_date_format: store.state.applicationState.dateTimeFormat,
      lunch_date_format: "HH:mm",

      columns: [
        {
          title: "Shift ID",
          dataIndex: "id",
          key: "id",
          width: "100px",
          sorter: (a, b) => a.id - b.id,
        },
        {
          title: "Associate Number",
          dataIndex: "associate_number",
          key: "associate_number",
          width: "120px",
          sorter: (a, b) => a.associate_number - b.associate_number,
        },
        {
          title: "Shift",
          dataIndex: "shift_number",
          key: "shift_number",
          width: "120px",
          filters: [],
          onFilter: (value, record) =>
            String(record.shift_number).indexOf(value) === 0,
          sorter: (a, b) => a.shift_number - b.shift_number,
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          width: "120px",
          sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
        },
        {
          title: "Weekday",
          dataIndex: "weekday",
          key: "weekday",
          width: "110px",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) =>
            a.name == null
              ? -1
              : b.name == null
              ? 1
              : a.name.localeCompare(b.name),
        },
        {
          title: "Overtimes",
          children: [
            {
              title: "Authorized",
              children: [
                {
                  title: "OT1, h",
                  dataIndex: "overt1_signed",
                  key: "overt1_signed",
                  sorter: (a, b) =>
                    Number(a.overt1_signed) - Number(b.overt1_signed),
                },
                {
                  title: "OT2, h",
                  dataIndex: "overt2_signed",
                  key: "overt2_signed",
                  sorter: (a, b) =>
                    Number(a.overt2_signed) - Number(b.overt2_signed),
                },
              ],
            },
            {
              title: "Unauthorized",
              children: [
                {
                  title: "OT1, h",
                  dataIndex: "overt1",
                  key: "overt1",
                  sorter: (a, b) => Number(a.overt1) - Number(b.overt1),
                },
                {
                  title: "OT2, h",
                  dataIndex: "overt2",
                  key: "overt2",
                  sorter: (a, b) => Number(a.overt2) - Number(b.overt2),
                },
              ],
            },
          ],
        },
      ],

      table_height: null,
    };
  },

  watch: {
    selected_employees() {
      this.loadData();
    },
    dateFromString() {
      this.loadData();
    },
    dateToString() {
      this.loadData();
    },
  },

  mixins: [api],

  computed: {
    dateFromString() {
      return this.date_from
        ? moment.utc(this.date_from).format(this.serverDateFormat)
        : null;
    },
    dateToString() {
      return this.date_to
        ? moment.utc(this.date_to).format(this.serverDateFormat)
        : null;
    },
  },

  methods: {
    async onKeydown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        event.preventDefault();
        try {
          this.$nextTick(this.printTable);
        } catch (error) {
          console.error(error);
        }
      }
    },
    printTable() {
      printJS({
        printable: this.table_data,
        properties: [
          { field: "id", displayName: "Shift ID" },
          { field: "associate_number", displayName: "Associate Number" },
          { field: "shift_number", displayName: "Shift" },
          { field: "date", displayName: "Date" },
          { field: "weekday", displayName: "Weekday" },
          { field: "name", displayName: "Name" },
          { field: "overt1_signed", displayName: "Authorized OT1, h" },
          { field: "overt2_signed", displayName: "Authorized OT2, h" },
          { field: "overt1", displayName: "Unauthorized OT1, h" },
          { field: "overt2", displayName: "Unauthorized OT2, h" },
        ],
        type: "json",
        header: "<h4>Overtime Report</h4>",
        style: "tr { page-break-inside: avoid; page-break-after: auto; }",
        documentTitle: " ",
      });
    },

    loadData() {
      this.not_found = false;
      this.table_data = [];
      this.loading = true;

      Object.assign(this.$data.columns, this.$options.data().columns);
      this.apiGetOvertimeReport(
        this.selected_employees,
        this.dateFromString,
        this.dateToString
      )
        .then((response) => {
          if (response.data && response.data.overtime_records) {
            this.table_data = response.data.overtime_records.map((rec) => {
              if (rec.shift_number == null) {
                rec.shift_number = "";
              }

              return {
                ...rec,
                overt1: Number(rec.overt1).toFixed(2).toLocaleString(),
                overt2: Number(rec.overt2).toFixed(2).toLocaleString(),
                overt1_signed: Number(rec.overt1_signed)
                  .toFixed(2)
                  .toLocaleString(),
                overt2_signed: Number(rec.overt2_signed)
                  .toFixed(2)
                  .toLocaleString(),
              };
            });
          }

          if (this.table_data.length == 0) {
            this.not_found = true;
          }
        })
        .catch((err) => console.error(err))
        .finally(() => (this.loading = false));
    },
    clearData() {
      this.not_found = false;
      this.date_from = null;
      this.date_to = null;
      this.table_data = [];
      this.found_employees = [];
      this.selected_employees = [];
    },

    loadShiftNumbersData() {
      this.apiGetShifts().then((response) => {
        const shifts = response.body.shifts;
        const filters = this.columns.find(
          (col) => col.key == "shift_number"
        ).filters;

        shifts.forEach((filter) => {
          filters.push({
            text: filter.code,
            value: filter.code,
          });
        });
      });
    },

    disabledStartDate(startValue) {
      const endValue = this.date_to;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.date_from;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
  },

  mounted() {
    this.loadShiftNumbersData();
    this.loadData();
  },
};
</script>
